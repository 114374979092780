import React from "react"
//import Modal from 'react-awesome-modal';

import { Layout, SEO } from "../components"

import styles from "./communications-css-module.module.scss"


import SolvLogo from '../../static/solv_logo.svg'



const Contact = () => {

    const documents = [
        { name: 'MGT 7 FY 2022-23', color: '#FFE4CF'} ,
        { name: 'NOTICE OF 4TH AGM', color: '#E9F0FC'} ,
        { name: 'MGT 7 FY 2021-22', color: '#DCFAE9'} ,
        { name: 'MGT 7 FY 2020-21', color: '#E8DAEE'} ,
        { name: 'ANNUAL RETURN FY 2019-20', color: '#FFE4CF'},
        { name: 'SCRTIPL NOTICE OF 12th EGM', color: '#E9F0FC'} ,
        { name: 'MGT 7 FY 2023-24', color: '#E0CCE9'} ,
        { name: 'NOTICE OF 5TH AGM', color: '#BBDECA'} ,
    ]

    function downloadReport(name) {
        let ScfFlyer = `/${name}.pdf`
        const fileName = `${name}.pdf`
        fetch(ScfFlyer).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = fileName;
                alink.click();
            })
        });
    }


    return (
        <>
            <Layout>
                <SEO
                    title="Communiactions | Solv - B2B Marketplace for MSME"
                    description=""
                />
                <section>
                    <div className={styles.communications}>
                        <p className={styles.title}>Communications</p>
                        <div className={styles.grid_container}>
                            {documents.map((document) => {
                                return  <div onClick={() => downloadReport(document.name)}>
                                <div className={`${styles.grid_item} ${styles.color_FFE4CF}`} style={{"backgroundColor": document.color}}>
                                    <div>
                                        <img src={SolvLogo} />
                                    </div>
                                    <div>
                                        <span className={styles.file_name_text}>{document.name}</span>
                                    </div>
                                </div>
                                <div>
                                    <span className={styles.file_name_outer_text}>{document.name}</span>
                                </div>
                                </div> 
                            })
                                
                            }


                        </div>
                    </div>

                </section>

            </Layout>
        </>
    )
}

export default Contact
